import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import Cookie from 'js-cookie'
import resolvers from './resolvers'
import typeDefs from './typedefs'
import { TOKEN_KEY, INITIAL_FORM_STATE } from '../utils/constants'

const cache = new InMemoryCache()
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API,
})
const authLink = setContext((_, { headers }) => {
  const token = Cookie.get(TOKEN_KEY)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${ token }` : '' 
    }
  }
})
const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    authLink,
    httpLink
  ]),
  typeDefs,
  resolvers
})

cache.writeData({
  data: {
    formState: INITIAL_FORM_STATE
  }
})

export default client
