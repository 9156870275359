import React from 'react'
import { Route } from 'react-router-dom'
import LoadingPage from '../pages/LoadingPage'

const UnprotectedRoute = ({
  currentUser,
  loading,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={ props => {
      let ele
      if(loading) {
        ele = <LoadingPage message="Memuat Data" />
      } else {
        ele = (
          <Component
            {...props}
            currentUser={ currentUser } />
        )
      }
      return ele
    }} />
)

export default UnprotectedRoute
