import gql from 'graphql-tag'

export const FORM_DATA = gql`
  fragment FormData on Form {
    name
    description
    order
    status
    type
  }
`

export const ELEMENT_DATA = gql`
  fragment ElementData on FormElement {
    type
    typeId
    order
    label
    required
    options
    optionsWithOther
    min
    minLabel
    max
    maxLabel
    gridData {
      rows
      columns
    }
    tableColumns {
      title
      hasOptions
      options
    }
  }
`

export const FORM_QUERY = gql`
  query form($id: ID) {
    form(id: $id) {
      id
      ...FormData
      elements {
        id
        ...ElementData
      }
    }
  }
  ${ FORM_DATA }
  ${ ELEMENT_DATA }
`

export const FORMS_QUERY = gql`
  query forms($pagination: PaginationInput!) {
    forms(pagination: $pagination) {
      limit
      page
      pages
      count
      result {
        id
        ...FormData
      }
    }
  }
  ${ FORM_DATA }
`

export const CREATE_FORM_MUTATION = gql`
  mutation createForm($form: FormInput) {
    createForm(form: $form) {
      id
      ...FormData
      elements {
        id
        ...ElementData
      }
    } 
  }
  ${ FORM_DATA }
  ${ ELEMENT_DATA }
`

export const UPDATE_FORM_MUTATION = gql`
  mutation updateForm($id: ID, $form: FormInput) {
    updateForm(id: $id, form: $form) {
      id
      ...FormData
      elements {
        id
        ...ElementData
      }
    }
  }
  ${ FORM_DATA }
  ${ ELEMENT_DATA }
`

export const FORM_STATE_QUERY = gql`
  query formState {
    formState @client {
      id
      name
      description
      status
      type
      elements {
        id
        type
        typeId
        label
        required
        options
        optionsWithOther
        min
        minLabel
        max
        maxLabel
        gridData {
          rows
          columns
        }
        tableColumns {
          title
          hasOptions
          options
        }
      }
    }
  }
`

export const RESET_FORM_DATA_MUTATION = gql`
  mutation {
    resetFormData @client
  }
` 

export const RESET_ELEMENTS_MUTATION = gql`
  mutation {
    resetElements @client
  }
`

export const UPDATE_FORM_PROPERTY_MUTATION = gql`
  mutation updateFormProperty($key: String, $value: String) {
    updateFormProperty(key: $key, value: $value) @client
  }
` 

export const ADD_FORM_ELEMENT_MUTATION = gql`
  mutation addFormElement($element: ElementStateInput) {
    addFormElement(element: $element) @client
  }
`

export const REMOVE_FORM_ELEMENT_MUTATION = gql`
  mutation removeFormElement($index: Int) {
    removeFormElement(index: $index) @client
  }
` 

export const REORDER_ELEMENTS_MUTATION = gql`
  mutation reorderElements($oldIndex: Int, $newIndex: Int) {
    reorderElements(oldIndex: $oldIndex, newIndex: $newIndex) @client
  }
`

export const UPDATE_FORM_ELEMENT_MUTATION = gql`
  mutation updateFormElement($key: String, $value: String, $index: Int) {
    updateFormElement(key: $key, value: $value, index: $index) @client
  }
`

export const ENTITY_FORMS_QUERY = gql`
  query entityForm($modelName: String){
    entityForm(modelName: $modelName) {
      id
      forms {
        id
      }
    }
  }
`
