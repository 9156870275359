import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import client from './gql/client'
import App from './App'

import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'

import 'normalize.css/normalize.css'
import 'flexboxgrid/dist/flexboxgrid.min.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import './styles/main.scss'

render((
  <BrowserRouter>
    <ApolloProvider client={ client }>
      <App />
    </ApolloProvider>
  </BrowserRouter>
), document.getElementById('root'))
