export const TOKEN_KEY = '_pha_token_'
//export const INDONESIA_CENTER = [-2.600029, 118.015776]
export const INDONESIA_CENTER = [-1.2, 120]
export const TAXONOMIES = {
  PROVINSI: 1,
  KOTAKABUPATEN: 2,
  KECAMATAN: 3,
  KELURAHANDESA: 4
}
export const FORM_ELEMENTS = {
  ISIAN: 1,
  ESSAY: 2,
  LINEAR_SCALE: 3,
  PILIHAN_GANDA: 4,
  CHECKBOX: 5,
  PRIORITAS: 6,
  PILIHAN_GANDA_GRID: 7,
  CHECKBOX_GRID: 8,
  ISIAN_GRID: 11,
  GROUP: 9,
  TABLE: 10,
  TITLE: 12
}
export const INITIAL_FORM_STATE = {
  id: 1,
  name: '',
  description: '',
  status: 0,
  type: '',
  elements: [],
  __typename: 'FormState'
}

export const TOOLBAR_CONFIG = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS'
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
}
export const USER_ROLES = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'GROUPADMIN', label: 'Admin Group' },
  { value: 'USER', label: 'Pengguna' }
]
