import { arrayMove } from 'react-sortable-hoc'
import { FORM_STATE_QUERY } from '../gql/schemas/form'
import { INITIAL_FORM_STATE } from '../utils/constants'

const queryObj = {
  query: FORM_STATE_QUERY
}

export default {
  Query: {
    formState(_, __, { cache }) {
      const { formState } = cache.readQuery(queryObj)
      return formState
    }
  },
  Mutation: {
    resetFormData(_, __, { cache }) {
      const data = {
        formState: INITIAL_FORM_STATE
      }
      cache.writeData({
        data,
        ...queryObj
      })
      return null
    },
    resetElements(_, __, { cache }) {
      const { formState } = cache.readQuery(queryObj)
      const data = {
        formState: {
          ...formState,
          elements: []
        }
      }
      cache.writeData({
        data,
        ...queryObj
      })
      return null
    },
    updateFormProperty(_, { key, value }, { cache }) {
      const { formState } = cache.readQuery(queryObj)
      const data = {
        formState: {
          ...formState,
          [key]: value
        }
      }
      cache.writeData({
        data,
        ...queryObj
      })
      return null
    },
    addFormElement(_, { element }, { cache }) {
      const { formState } = cache.readQuery(queryObj)
      const data = {
        formState: {
          ...formState,
          elements: [
            ...formState.elements,
            element
          ]
        }
      }
      cache.writeData({
        data,
        ...queryObj
      })
      return null
    },
    removeFormElement(_, { index }, { cache }) {
      const { formState } = cache.readQuery(queryObj)
      const data = {
        formState: {
          ...formState,
          elements: [
            ...formState.elements.slice(0, index),
            ...formState.elements.slice(index + 1)
          ]
        }
      }
      cache.writeData({
        data,
        ...queryObj
      })
      return null
    },
    reorderElements(_, { oldIndex, newIndex }, { cache }) {
      const { formState } = cache.readQuery(queryObj)
      const data = {
        formState: {
          ...formState,
          elements: arrayMove(formState.elements, oldIndex, newIndex)
        }
      }
      cache.writeData({
        data,
        ...queryObj
      })
      return null
    },
    updateFormElement(_, { key, value, index }, { cache }) {
      const { formState } = cache.readQuery(queryObj)
      const targetElement = formState.elements[index]
      let newValue = value
      if(key === 'min' || key === 'max') newValue = parseInt(value, 10)
      const updatedElement = {
        ...targetElement,
        [key]: newValue
      }
      const data = {
        formState: {
          ...formState,
          elements: [
            ...formState.elements.slice(0, index),
            updatedElement,
            ...formState.elements.slice(index + 1)
          ] 
        }
      }
      cache.writeData({
        data,
        ...queryObj
      })
      return null
    }
  }
}
