import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Navbar,
  Popover,
  Menu,
  Button,
  Alignment,
  Position
} from '@blueprintjs/core'
import { goLogout } from '../utils'
import logo from '../images/huma.png'

const MainMenu = (props) => {
  const { currentUser, history } = props
  return (
    <Navbar fixedToTop className="main-menu">
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading onClick={() => history.push('/')}>
          <img alt="logo" className="logo" src={logo} />
        </Navbar.Heading>
        <Navbar.Divider />
        {currentUser && (
          <Fragment>
            <Popover
              minimal
              content={
                <Menu>
                  <Menu.Item
                    icon="list"
                    text="Daftar Lokasi"
                    onClick={() => history.push('/locations')}
                  />
                  <Menu.Item
                    icon="add"
                    text="Tambah Lokasi Baru"
                    onClick={() => history.push('/locations/add')}
                  />
                </Menu>
              }
              position={Position.BOTTOM_LEFT}>
              <Button className="bp3-minimal" icon="map-marker" text="Lokasi" />
            </Popover>
            <Popover
              minimal
              content={
                <Menu>
                  <Menu.Item
                    icon="list"
                    text="Daftar PHR"
                    onClick={() => history.push('/phrs')}
                  />
                  <Menu.Item
                    icon="add"
                    text="Tambah PHR Baru"
                    onClick={() => history.push('/phrs/add')}
                  />
                </Menu>
              }
              position={Position.BOTTOM_LEFT}>
              <Button className="bp3-minimal" icon="briefcase" text="PHR" />
            </Popover>
            {currentUser && currentUser.role === 'ADMIN' && (
              <Popover
                minimal
                content={
                  <Menu>
                    <Menu.Item
                      icon="list"
                      text="Daftar Pengguna"
                      onClick={() => history.push('/users')}
                    />
                    <Menu.Item
                      icon="add"
                      text="Tambah Pengguna Baru"
                      onClick={() => history.push('/users/add')}
                    />
                  </Menu>
                }
                position={Position.BOTTOM_LEFT}>
                <Button className="bp3-minimal" icon="people" text="Pengguna" />
              </Popover>
            )}
            {currentUser && currentUser.role === 'ADMIN' && (
              <Popover
                minimal
                content={
                  <Menu>
                    <Menu.Item
                      icon="list"
                      text="Daftar Group"
                      onClick={() => history.push('/groups')}
                    />
                    <Menu.Item
                      icon="add"
                      text="Tambah Group"
                      onClick={() => history.push('/groups/add')}
                    />
                  </Menu>
                }
                position={Position.BOTTOM_LEFT}>
                <Button className="bp3-minimal" icon="duplicate" text="Groups" />
              </Popover>
            )}
          </Fragment>
        )}
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {currentUser ? (
          <Fragment>
            <Popover
              minimal
              content={
                <Menu>
                  <Menu.Item
                    icon="edit"
                    text="Pembaruan Profil"
                    onClick={() => history.push('/profile')}
                  />
                  <Menu.Item
                    icon="log-out"
                    text="Log Out"
                    onClick={() => goLogout()}
                  />
                </Menu>
              }
              position={Position.BOTTOM_RIGHT}>
              <Button
                className="bp3-minimal"
                icon={
                  currentUser.profileImage ? (
                    <img
                      className="icon-propic"
                      alt={`${currentUser.firstName} ${currentUser.lastName}`}
                      src={currentUser.profileImage}
                    />
                  ) : (
                    'user'
                  )
                }
                text={`Halo, ${currentUser.firstName}`}
              />
            </Popover>
          </Fragment>
        ) : (
          <Fragment>
            <Button
              className="bp3-minimal"
              icon="log-in"
              text="Login"
              onClick={() => history.push('/login')}
            />
            <Button
              className="bp3-minimal"
              icon="walk"
              text="Register"
              onClick={() => history.push('/register')}
            />
          </Fragment>
        )}
      </Navbar.Group>
    </Navbar>
  )
}

export default withRouter(MainMenu)
