import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({
  currentUser,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={ props => {
      let ele
      if(currentUser) {
        ele = (
          <Component
            {...props}
            currentUser={ currentUser } />
        )
      } else {
        const restPath = encodeURI(rest.path)
        ele = (
          <Redirect
            to={{
              pathname: '/',
              search: `?redirect=${ restPath.substr(1) }`,
              state: { from: props.location }
            }} />
        )
      }
      return ele
    }} />
)

export default ProtectedRoute
