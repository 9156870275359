import React, { Fragment } from 'react'
import Loadable from 'react-loadable'
import { useQuery } from '@apollo/react-hooks'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import UnprotectedRoute from './components/UnprotectedRoute'
import ErrorBoundary from './components/ErrorBoundary'
import MainMenu from './components/MainMenu'
import LoadingPage from './pages/LoadingPage'

import { ME_QUERY } from './gql/schemas/user'

const NoMatch = Loadable({
  loader: () => import('./pages/NoMatch' /* webpackChunkName: 'no-match' */),
  loading: LoadingPage
})
const Login = Loadable({
  loader: () => import('./pages/auth/Login' /* webpackChunkName: 'login' */),
  loading: LoadingPage
})
const Register = Loadable({
  loader: () =>
    import('./pages/auth/Register' /* webpackChunkName: 'register' */),
  loading: LoadingPage
})
const RequestPassword = Loadable({
  loader: () =>
    import(
      './pages/auth/RequestPassword' /* webpackChunkName: 'request-password' */
    ),
  loading: LoadingPage
})
const ResetPassword = Loadable({
  loader: () =>
    import(
      './pages/auth/ResetPassword' /* webpackChunkName: 'reset-password' */
    ),
  loading: LoadingPage
})
const Dashboard = Loadable({
  loader: () => import('./pages/Dashboard' /* webpackChunkName: 'dashboard' */),
  loading: LoadingPage
})
const ProfileUpdate = Loadable({
  loader: () =>
    import(
      './pages/auth/ProfileUpdate' /* webpackChunkName: 'profile-update' */
    ),
  loading: LoadingPage
})
const FormAdd = Loadable({
  loader: () => import('./pages/forms/Add' /* webpackChunkName: 'form-add' */),
  loading: LoadingPage
})
const FormList = Loadable({
  loader: () =>
    import('./pages/forms/List' /* webpackChunkName: 'form-list' */),
  loading: LoadingPage
})
const FormSingle = Loadable({
  loader: () =>
    import('./pages/forms/Single' /* webpackChunkName: 'form-single' */),
  loading: LoadingPage
})
const FamilyAdd = Loadable({
  loader: () =>
    import('./pages/families/Add' /* webpackChunkName: 'family-add' */),
  loading: LoadingPage
})
const FamilySingle = Loadable({
  loader: () =>
    import('./pages/families/Single' /* webpackChunkName: 'family-single' */),
  loading: LoadingPage
})
const MapOnly = Loadable({
  loader: () =>
    import('./pages/locations/Map' /* webpackChunkName: 'map-only' */),
  loading: LoadingPage
})
const MapOnlySingle = Loadable({
  loader: () =>
    import(
      './pages/locations/MapSingle' /* webpackChunkName: 'map-only-single' */
    ),
  loading: LoadingPage
})
const MapSearch = Loadable({
  loader: () =>
    import('./pages/locations/MapSearch' /* webpackChunkName: 'map-search' */),
  loading: LoadingPage
})
const LocationList = Loadable({
  loader: () =>
    import('./pages/locations/List' /* webpackChunkName: 'location-list' */),
  loading: LoadingPage
})
const LocationAdd = Loadable({
  loader: () =>
    import('./pages/locations/Add' /* webpackChunkName: 'location-add' */),
  loading: LoadingPage
})
const LocationSingle = Loadable({
  loader: () =>
    import(
      './pages/locations/Single' /* webpackChunkName: 'location-single' */
    ),
  loading: LoadingPage
})
const LocationPrint = Loadable({
  loader: () =>
    import('./pages/locations/Print' /* webpackChunkName: 'location-print' */),
  loading: LoadingPage
})
const LocationPublic = Loadable({
  loader: () =>
    import(
      './pages/locations/Public' /* webpackChunkName: 'location-public' */
    ),
  loading: LoadingPage
})
const GroupList = Loadable({
  loader: () =>
    import('./pages/groups/List' /* webpackChunkName: 'group-list' */),
  loading: LoadingPage
})
const GroupAdd = Loadable({
  loader: () =>
    import('./pages/groups/Add' /* webpackChunkName: 'group-add' */),
  loading: LoadingPage
})
const GroupSingle = Loadable({
  loader: () =>
    import('./pages/groups/Single' /* webpackChunkName: 'group-single' */),
  loading: LoadingPage
})
const TermAdd = Loadable({
  loader: () => import('./pages/terms/Add' /* webpackChunkName: 'term-add' */),
  loading: LoadingPage
})
const TermList = Loadable({
  loader: () =>
    import('./pages/terms/List' /* webpackChunkName: 'term-list' */),
  loading: LoadingPage
})
const TermSingle = Loadable({
  loader: () =>
    import('./pages/terms/Single' /* webpackChunkName: 'term-single' */),
  loading: LoadingPage
})
const UserAdd = Loadable({
  loader: () => import('./pages/users/Add' /* webpackChunkName: 'user-add' */),
  loading: LoadingPage
})
const UserList = Loadable({
  loader: () =>
    import('./pages/users/List' /* webpackChunkName: 'user-list' */),
  loading: LoadingPage
})
const UserSingle = Loadable({
  loader: () =>
    import('./pages/users/Single' /* webpackChunkName: 'user-single' */),
  loading: LoadingPage
})
const PhrList = Loadable({
  loader: () => import('./pages/phrs/List' /* webpackChunkName: 'phr-list' */),
  loading: LoadingPage
})
const PhrAdd = Loadable({
  loader: () => import('./pages/phrs/Add' /* webpackChunkName: 'phr-add' */),
  loading: LoadingPage
})
const PhrSingle = Loadable({
  loader: () => import('./pages/phrs/Single' /* webpackChunkName: 'phr-single' */),
  loading: LoadingPage
})

const App = () => {
  const { loading, data } = useQuery(ME_QUERY)
  const isAdmin = data && data.me && data.me.role === 'ADMIN'
  return (
    <Route
      render={({ location }) => {
        const isNoHeader =
          location.pathname.indexOf('map-only') >= 0 ||
          location.pathname.indexOf('print') >= 0
        return (
          <ErrorBoundary>
            <div className="app">
              {loading ? (
                <LoadingPage message="Memuat Data" />
              ) : (
                <Fragment>
                  {!isNoHeader && <MainMenu currentUser={data.me} />}
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/map-only" component={MapOnly} />
                    <Route
                      exact
                      path="/map-only-search"
                      component={MapSearch}
                    />
                    <Route
                      exact
                      path="/map-only/:id"
                      component={MapOnlySingle}
                    />
                    <UnprotectedRoute
                      exact
                      noMenu
                      path="/login"
                      component={Login}
                      currentUser={data.me}
                    />
                    <UnprotectedRoute
                      exact
                      noMenu
                      path="/register"
                      component={Register}
                      currentUser={data.me}
                    />
                    <Route
                      exact
                      noMenu
                      path="/ganti-kata-kunci"
                      component={RequestPassword}
                    />
                    <Route
                      exact
                      path="/reset/:token"
                      component={ResetPassword}
                    />
                    <ProtectedRoute
                      exact
                      noMenu
                      path="/dashboard"
                      component={Dashboard}
                      currentUser={data.me}
                    />
                    <ProtectedRoute
                      exact
                      path="/profile"
                      component={ProfileUpdate}
                      currentUser={data.me}
                    />
                    <ProtectedRoute
                      exact
                      path="/locations"
                      component={LocationList}
                      currentUser={data.me}
                    />
                    <ProtectedRoute
                      exact
                      path="/locations/add"
                      component={LocationAdd}
                      currentUser={data.me}
                    />
                    <ProtectedRoute
                      exact
                      path="/locations/:id/family/add"
                      component={FamilyAdd}
                      currentUser={data.me}
                    />
                    <ProtectedRoute
                      exact
                      path="/locations/:locationId/family/:id"
                      component={FamilySingle}
                      currentUser={data.me}
                    />
                    <Route
                      exact
                      path="/locations/:id"
                      component={LocationSingle}
                      currentUser={data.me}
                    >
                      {(props) => {
                        if (
                          (data && data.me && data.me.role === 'ADMIN') ||
                          (data && data.me && data.me.role === 'GROUPADMIN') ||
                          (data && data.me && data.me.role === 'USER')
                        ) {
                          return (
                            <LocationSingle {...props} currentUser={data.me} />
                          )
                        } else {
                          return <LocationPublic {...props} />
                        }
                      }}
                    </Route>
                    <Route
                      exact
                      path="/locations/:id/print"
                      component={LocationPrint}
                      currentUser={data.me}
                    >
                      {(props) => {
                        if (
                          (data && data.me && data.me.role === 'ADMIN') ||
                          (data && data.me && data.me.role === 'GROUPADMIN') ||
                          (data && data.me && data.me.role === 'USER')
                        ) {
                          return (
                            <LocationPrint {...props} currentUser={data.me} />
                          )
                        } else {
                          return <LocationPublic {...props} />
                        }
                      }}
                    </Route>
                    <ProtectedRoute
                      exact
                      path="/phrs"
                      component={PhrList}
                      currentUser={data.me}
                    />
                    <ProtectedRoute
                      exact
                      path="/phrs/add"
                      component={PhrAdd}
                      currentUser={data.me}
                    />
                    <ProtectedRoute
                      exact
                      path="/phrs/:id"
                      component={PhrSingle}
                      currentUser={data.me}
                    />
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/groups"
                        component={GroupList}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/groups/add"
                        component={GroupAdd}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/groups/:id"
                        component={GroupSingle}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/forms"
                        component={FormList}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/forms/add"
                        component={FormAdd}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/forms/:id"
                        component={FormSingle}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/terms"
                        component={TermList}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/terms/add"
                        component={TermAdd}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/terms/:id"
                        component={TermSingle}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/users"
                        component={UserList}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/users/add"
                        component={UserAdd}
                        currentUser={data.me}
                      />
                    )}
                    {isAdmin && (
                      <ProtectedRoute
                        exact
                        path="/users/:id"
                        component={UserSingle}
                        currentUser={data.me}
                      />
                    )}
                    <Route component={NoMatch} />
                  </Switch>
                </Fragment>
              )}
            </div>
          </ErrorBoundary>
        )
      }}
    />
  )
}

export default App
