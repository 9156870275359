import gql from 'graphql-tag'

export const USER_DATA = gql`
  fragment UserData on User {
    email
    firstName
    lastName
    profileImage
    description
    role
  }
`

export const ME_QUERY = gql`
  query {
    me {
      id
      ...UserData
      groups {
        members {
          user {
            id
          }
        }  
      } 
    }
  } 
  ${ USER_DATA }
`

export const USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      ...UserData
    }
  }
  ${ USER_DATA }
`

export const USERS_QUERY = gql`
  query users($search: String, $pagination: PaginationInput!) {
    users(search: $search, pagination: $pagination) {
      limit
      page
      pages
      count
      result {
        id
        ...UserData
      }
    }
  }
  ${ USER_DATA }
`

export const CHECK_RESET_LINK_QUERY = gql`
  query checkResetLink($token: String) {
    checkResetLink(token: $token)
  }
`

export const SIGNIN_MUTATION = gql`
  mutation signIn($auth: AuthInput!) {
    signIn(auth: $auth) {
      token
      user {
        id
        ...UserData
      }
    }
  }
  ${ USER_DATA }
`

export const REGISTER_MUTATION = gql`
  mutation register($auth: AuthInput!, $profile: ProfileInput) {
    register(auth: $auth, profile: $profile) {
      token
      user {
        id
        ...UserData
      }
    }
  } 
  ${ USER_DATA }
`

export const CREATE_USER_MUTATION = gql`
  mutation createUser($auth: AuthInput!, $profile: ProfileInput, $role: String) {
    createUser(auth: $auth, profile: $profile, role: $role) {
      id
      ...UserData
    }
  } 
  ${ USER_DATA }
`

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($id: ID!, $profile: ProfileInput, $role: String) {
    updateUser(id: $id, profile: $profile, role: $role) {
      id
      ...UserData
    }
  }
  ${ USER_DATA }
`

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`

export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($id: ID!, $profile: ProfileInput) {
    updateProfile(id: $id, profile: $profile) {
      id
      ...UserData
    }
  }
  ${ USER_DATA }
`

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation requestPasswordReset($email: String) {
    requestPasswordReset(email: $email) {
      id
      ...UserData
    }
  }
  ${ USER_DATA }
`

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePassword($email: String, $token: String) {
    updatePassword(email: $email, token: $token) {
      id
      ...UserData
    }
  }
  ${ USER_DATA }
`
