import moment from 'moment'
import Cookie from 'js-cookie'
import { USER_ROLES, TOKEN_KEY } from './constants'

const idLocale = require('moment/locale/id')
moment.updateLocale('id', idLocale)

export const formatDate = (theDate) =>
  moment(theDate).calendar(null, {
    sameDay: () => `[${moment(theDate).fromNow()}]`,
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday], LT',
    lastWeek: 'DD MMM YYYY',
    sameElse: 'DD MMM YYYY'
  })

export const canvasToBlob = (canvas, format = 'image/jpeg', quality = 0.8) =>
  new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      format,
      quality
    )
  })

export const numberWithSeparator = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const isJson = (str) => {
  try {
    const json = JSON.parse(str)
    return typeof json === 'object'
  } catch (e) {
    return false
  }
}

export const goLogout = () => {
  Cookie.remove(TOKEN_KEY)
  window.location.href = '/'
}

export const getRoleName = (value) => USER_ROLES.find((u) => u.value === value)

export const formatRowElement = (row, formId, locationId, result) => {
  if (!row) return null
  let rowResult
  const { type, label, options, gridData } = row
  if (type === 'Title') {
    rowResult = {
      formId: null,
      locationId: null,
      elementId: null,
      label: label,
      jawaban: null
    }
  }
  if (type === 'Isian' || type === 'Essay') {
    rowResult = {
      formId,
      locationId,
      elementId: row.id,
      label: label,
      jawaban: result
    }
  }
  if (type === 'Pilihan Ganda') {
    rowResult = {
      formId,
      locationId,
      elementId: row.id,
      label: `${label}, Pilihan jawaban: (${options.join(', ')})`,
      jawaban: result
    }
  }
  if (type === 'Isian Grid') {
    const { rows: iRows, columns } = gridData
    const gridRowResult = []
    const resultDict = result ? JSON.parse(result) : null
    iRows.forEach((iRow, r) => {
      columns.forEach((col, c) => {
        gridRowResult.push({
          formId,
          locationId,
          elementId: `${row.id}_${r}_${c}`,
          label: `- ${iRow} - ${col}`,
          jawaban: resultDict && resultDict[iRow] ? resultDict[iRow][c] : null
        })
      })
    })
    rowResult = [{
      formId,
      locationId,
      elementId: row.id,
      label,
      jawaban: null
    }, ...gridRowResult]
  }
  if (type === 'Pilihan Ganda Grid') {
    const { rows: oRows, columns } = gridData
    const gridRowResult = []
    const resultDict = result ? JSON.parse(result) : null
    oRows.forEach((oRow, r) => {
      gridRowResult.push({
        formId,
        locationId,
        elementId: `${row.id}_${r}`,
        label: `- ${oRow}, Pilihan jawaban: (${columns.join(', ')})`,
        jawaban: resultDict && resultDict[oRow] ? resultDict[oRow] : null
      })
    })
    rowResult = [{
      formId,
      locationId,
      elementId: row.id,
      label,
      jawaban: null
    }, ...gridRowResult]
  }
  return rowResult
}
