import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: '',
    info: ''
  }

  componentDidCatch(error, info) {
    this.setState({ 
      error,
      info,
      hasError: true 
    })
  }

  render() {
    let ele
    if(this.state.hasError) {
      ele = (
        <div className="app">
          <div className="simple-container">
            <p> Error memuat halaman </p>
            <Button
              onClick={() => console.log(this.state)}
              text="Kirim Error Report" />
          </div>
        </div>
      )
    } else {
      ele = this.props.children
    }
    return ele
  }
}
