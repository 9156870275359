import gql from 'graphql-tag'

export default gql`
  type FormState {
    id: Int
    name: String
    description: String
    status: Int
    type: String
    elements: [ ElementState ]
  }

  type GridState {
    rows: [ String ]
    columns: [ String ]
  }

  input GridStateInput {
    rows: [ String ]
    columns: [ String ]
  }

  type TableState {
    title: String
    hasOptions: Boolean
    options: [ String ]
  }

  type TableStateInput {
    title: String
    hasOptions: Boolean
    options: [ String ]
  }

  type ElementState {
    id: Int
    type: String
    typeId: Int
    label: String
    required: Boolean
    options: [ String ]
    optionsWithOther: Boolean
    min: Int
    minLabel: String
    max: Int
    maxLabel: String
    gridData: GridState
    tableColumns: [ TableState ]
  }

  input ElementStateInput {
    id: Int
    type: String
    typeId: Int
    label: String
    required: Boolean
    options: [ String ]
    optionsWithOther: Boolean
    min: Int
    minLabel: String
    max: int
    maxLabel: String
    gridData: GridStateInput
    tableColumns: [ TableStateInput ]
  }

  extend type Query {
    formState: FormState
  }
`
