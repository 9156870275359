import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '@blueprintjs/core'

const LoadingPage = ({
  message,
  error,
  retry,
  pastDelay
}) => (
  <div className="app">
    <Helmet>
      <title>Memuat Data...</title>
    </Helmet>
    {
      error &&
        <div className="simple-container">
          <p>Error memuat halaman</p>
          <Button intent="success" onClick={ retry }>Muat Ulang</Button>
        </div>
    }
    {
      (pastDelay || message) && 
        <div className="simple-container">Memuat Data...</div>
    }
  </div>
)

export default LoadingPage
